<template>
  <div
    v-if="isShow"
    class="page__login-newUI-third"
  >
    <div class="line-hr">
      <span>{{ langText.SHEIN_KEY_PWA_15600 }}</span>
    </div>
    <div class="third-list">
      <s-button
        v-if="isShowKakao"
        class="kakao-btn"
        :type="['H88PX']"
        @click="handleKakaoLogin"
      >
        <span>
          <KakaoIcon />
        </span>
        <em>{{ langText.SHEIN_KEY_PWA_29542 }}</em>
      </s-button>
      <s-button
        v-if="isShowNaver"
        class="naver-btn"
        :type="['H88PX']"
        @click="handleNaverLogin"
      >
        <span>
          <NaverIcon />
        </span>
        <em>{{ langText.SHEIN_KEY_PWA_29543 }}</em>
      </s-button>
      <s-button
        v-if="isShowLine"
        class="line-btn"
        :type="['H88PX']"
        @click="handleLineLogin"
      >
        <span>
          <LineIcon />
        </span>
        <em>{{ langText.SHEIN_KEY_PWA_23926 }}</em>
      </s-button>
      <s-button
        v-if="isShowVk"
        class="vk-btn"
        :type="['H88PX']"
        @click="vkLoginFun"
      >
        <span>
          <VKIcon />
        </span>
        <em>{{ langText.SHEIN_KEY_PWA_23927 }}</em>
      </s-button>
      <s-button
        v-if="isShowGoogle"
        class="google-btn"
        :type="['H88PX']"
        @click="handleGoogleBtn"
      >
        <span>
          <GoogleIcon />
        </span>
        <em>{{ langText.SHEIN_KEY_PWA_23924 }}</em>
      </s-button>
      <s-button
        v-if="isShowFacebook"
        class="facebook-btn"
        :type="['H88PX']"
        @click="handleFacebookBtn"
      >
        <span>
          <FacebookIcon />
        </span>
        <em>{{ langText.SHEIN_KEY_PWA_23923 }}</em>
      </s-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import thirdMixin from 'public/src/pages/login/mixin/third_part_login.js'
import GoogleIcon from 'public/src/pages/login/components/Icon/GoogleIcon.vue'
import LineIcon from 'public/src/pages/login/components/Icon/LineIcon.vue'
import FacebookIcon from 'public/src/pages/login/components/Icon/FacebookIcon.vue'
import VKIcon from 'public/src/pages/login/components/Icon/VKIcon.vue'
import KakaoIcon from 'public/src/pages/login/components/Icon/KakaoIcon.vue'
import NaverIcon from 'public/src/pages/login/components/Icon/NaverIcon.vue'

export default {
  name: 'PageLoginNewUIThird',
  components: {
    GoogleIcon,
    LineIcon,
    KakaoIcon,
    VKIcon,
    FacebookIcon,
    NaverIcon
  },
  mixins: [ thirdMixin ],
  computed: {
    ...mapState('login', ['langText'])
  },
}

</script>

<style lang="less">
.icon-size(){
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;  
}
.page__login-newUI-third {
  margin-top: 52px;
  .line-hr {
    display: flex;
    color: @sui_color_gray_light1;
    justify-content: center;
    align-items: center;
    span {
      padding: 0 12px;
    }
    &::before, &::after {
      content: '';
      width: 90px;
      height: 1px;
      background: #E5E5E5;
    }
  }
  .third-list {
    padding-top: 20px;
    .S-button {
      width: 100%;
      font-weight: normal;
      border-color: @sui_color_gray_weak1;
      display: flex;
      align-items: center;
      // justify-content: center;
      margin-bottom: 12px;
      span {
        display: flex;
        margin-left: 10%;
      }
      em {
        font-size: 14px;
        .padding-l(16px);
        // flex: 1 1 30%;
        .txt-l();
      }
    }
  }
  .google-btn{   
    span {
      .icon-size();
    }
  }
  .facebook-btn {
    span {
      .icon-size();
      background: #3949AB;
      border-radius: 50%;
      color: #fff;
    }
    i {
        position: relative;
        font-size: 30px;
        top: 2px;
      }
  }
  .line-btn  {
    span {
      .icon-size();
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
  .vk-btn {
    span {
      .icon-size();
      color: #4680c2;
    }
    i {
        position: relative;
        font-size: 30px;
        top: 2px;
      }
  }
}
</style>
