<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
  >
    <path
      d="M44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44C34.1503 44 44 34.1503 44 22Z"
      fill="#3949AB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.982 33.5V22.2487H27.0883L27.5 18.3714H23.982L23.9873 16.4308C23.9873 15.4196 24.0834 14.8777 25.5361 14.8777H27.478V11H24.3713C20.6396 11 19.3261 12.8809 19.3261 16.044V18.3719H17V22.2491H19.3261V33.5H23.982Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'FacebookIcon'
}
</script>
